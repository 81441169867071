import React, { useState, useEffect } from "react";
import { Form } from "react-bootstrap"; // Assuming you're using react-bootstrap for Form.Select
import Cookies from 'js-cookie';
import { API_BASE_URL, MEDIA_BASE_URL, BASE_URL } from "../../config/config";


function CountriesRegister(props) {
  const [country, setCountry] = useState([]);
  const {name, onChange,selectedcountry,id}=props;
  const [selectedOption, setSelectedOption] = useState(selectedcountry);
  async function fetchVatData() {
    try {
      // "url": "/userRegistration/getcountry",
      const response = await fetch(`${API_BASE_URL}/userRegistration/getcountry`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json; charset=utf-8',         
        },
       
      });

      const data = await response.json();
      setCountry(data.d);
    
     // console.log(data.d);
    } catch (error) {
      console.log("Error fetching Country data:", error);
    }
  }



  useEffect(() => {
    setSelectedOption(selectedcountry); 
    fetchVatData();
  }, [selectedcountry]);


  const handleSelect = (event) => {
    if(onChange){
      setSelectedOption(event.target.value);
        onChange(event.target.value,event.target.options[event.target.selectedIndex].text, event.target.options[event.target.selectedIndex].getAttribute('flag'), event.target.options[event.target.selectedIndex].getAttribute('ccode'));
    }
     //const selectedValue = event.target.options[event.target.selectedIndex].getAttribute('flag');
    // setSelectedOption(selectedValue);
     //console.log("selected-"+selectedValue);    
  };

  return (
    <>
     <Form.Select onChange={handleSelect} name={name} value={selectedOption} id={id} aria-label="Default select example">
    {country.map((option, index) => (
        <option key={index} value={option.currency} flag={option.ISO2} ccode={option.CallingCode}>
        {option.CountryName}
        </option>
    ))}
    </Form.Select> 
    
    </>
  );
}

export default CountriesRegister;
