import React, {useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom';
import $ from "jquery";
import Cookies from 'js-cookie';
import swal from "sweetalert2";
import { API_BASE_URL, MEDIA_BASE_URL, BASE_URL } from "../../config/config";
import { encrypt, decrypt } from '../../config/encrypt-decrypt';

function TermsConditions () {





  useEffect(() => {

  },[]);
  
  
  



    return (
<>
<div className="page-wraper">
	<div className="page-content bg-white">		
		<section className="content-inner-2 pb-5">
			<div className="container">
				<div className="row">
					<div className="col-lg-12">
					
						<div className="tile" id="tile-1">
							<ul className="nav nav-tabs nav-tabs1 m-b30" id="myTab" role="tablist">
								<div className="slider"></div>
								<li>
									<div className="logo-header mostion logo-dark">
										<a href="index.html" style={{Padding:"5px 20px !important"}}><img style={{MaxWidth:"100px!important"}} src="images/logo1.png" alt="" /></a>
									</div>
								</li>
								<li className="nav-item" role="presentation">
									<a className="nav-link active" id="terms-tab" data-bs-toggle="tab" data-bs-target="#terms" type="button" role="tab" aria-controls="terms" aria-selected="true">TERMS</a>
								</li>
								<li className="nav-item" role="presentation">
									<a className="nav-link " id="terms-tab" data-bs-toggle="tab" data-bs-target="#privacy" type="button" role="tab" aria-controls="privacy" aria-selected="true">PRIVACY POLICY</a>
								</li>
							</ul>
							<div className="tab-content" id="myTabContent">
								<div className="tab-pane fade active show" id="terms" role="tabpanel" aria-labelledby="terms-tab">
									<div className="row">
										<div className="col-md-12 col-7">
											<div className="section-head style-1"><br />
												<h2 className="title">View Our Terms & Conditions</h2>
											</div>
										</div>
										{/* <div className="col-md-3 col-5">
											<div className="dropdown float-end">
												  <button className="btn shadow-primary btn-primary policy-but" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
													Select Language
												  </button>
												  <ul className="dropdown-menu policy-drop" aria-labelledby="dropdownMenuButton1">
													<li><a className="dropdown-item" href="#">ENG</a></li>
													<li><a className="dropdown-item" href="#">FI</a></li>
													<li><a className="dropdown-item" href="#">GE</a></li>
												  </ul>
											</div>
										</div> */}
									</div>
				
									<div className="row justify-content-center">
										<div className="col-lg-12">
											<p className="mb-2">These terms and conditions outline the rules and regulations for the use of ZWATO INVOICING Website, located at Website.com.</p>
											<p className="mb-2">By accessing this website we assume you accept these terms and conditions. Do not continue to use Website Name if you do not agree to take all of the terms and conditions stated on this page.</p>
											<p className="mb-5">The following terminology applies to these Terms and Conditions, Privacy Statement and Disclaimer Notice and all Agreements: "Client", "You" and "Your" refers to you, the person log on this website and compliant to the Company's terms and conditions. "The Company", "Ourselves", "We", "Our" and "Us", refers to our Company. "Party", "Parties", or "Us", refers to both the Client and ourselves. All terms refer to the offer, acceptance and consideration of payment necessary to undertake the process of our assistance to the Client in the most appropriate manner for the express purpose of meeting the Client's needs in respect of provision of the Company's stated services, in accordance with and subject to, prevailing law of Netherlands. Any use of the above terminology or other words in the singular, plural, capitalization and/or he/she or they, are taken as interchangeable and therefore as referring to same.</p>
											<h3>Cookies</h3>
											<p className="mb-2">We employ the use of cookies. By accessing Website Name, you agreed to use cookies in agreement with the ZWATO INVOICING Privacy Policy.</p>
											<p className="mb-5">Most interactive websites use cookies to let us retrieve the user's details for each visit. Cookies are used by our website to enable the functionality of certain areas to make it easier for people visiting our website. Some of our affiliate/advertising partners may also use cookies.</p>
											
											
											<h3>License</h3>
											<p className="mb-2">Unless otherwise stated, ZWATO INVOICING and/or its licensors own the intellectual property rights for all material on Website Name. All intellectual property rights are reserved. You may access this from Website Name for your own personal use subjected to restrictions set in these terms and conditions.</p>
											<p className="mb-2"><strong>You must not:</strong></p>
											<ul className="mb-2 auto-list">
												<li>Republish material from ZWATO INVOICING</li>
												<li>Sell, rent or sub-license material from ZWATO INVOICING</li>
												<li>Reproduce, duplicate or copy material from ZWATO INVOICING</li>
												<li>Redistribute content from ZWATO INVOICING</li>
											</ul>
											<p className="mb-2">This Agreement shall begin on the date hereof.</p>
											<p className="mb-2">Parts of this website offer an opportunity for users to post and exchange opinions and information in certain areas of the website. ZWATO INVOICING does not filter, edit, publish or review Comments prior to their presence on the website. Comments do not reflect the views and opinions of ZWATO INVOICING,its agents and/or affiliates. Comments reflect the views and opinions of the person who post their views and opinions. To the extent permitted by applicable laws, ZWATO INVOICING shall not be liable for the Comments or for any liability, damages or expenses caused and/or suffered as a result of any use of and/or posting of and/or appearance of the Comments on this website.</p>
											<p className="mb-5">ZWATO INVOICING reserves the right to monitor all Comments and to remove any Comments which can be considered inappropriate, offensive or causes breach of these Terms and Conditions.</p>
											
											<h3>iFrames</h3>
											<p className="mb-5">Without prior approval and written permission, you may not create frames around our Webpages that alter in any way the visual presentation or appearance of our Website.</p>
											
											<h3>Content Liability</h3>
											<p className="mb-5">We shall not be hold responsible for any content that appears on your Website. You agree to protect and defend us against all claims that is rising on your Website. No link(s) should appear on any Website that may be interpreted as libelous, obscene or criminal, or which infringes, otherwise violates, or advocates the infringement or other violation of, any third party rights.</p>
											<h3>Reservation of Rights</h3>
											<p className="mb-5">We reserve the right to request that you remove all links or any particular link to our Website. You approve to immediately remove all links to our Website upon request. We also reserve the right to amen these terms and conditions and it's linking policy at any time. By continuously linking to our Website, you agree to be bound to and follow these linking terms and conditions.</p>
											<h3>Removal of Links From Our Website</h3>
											<p className="mb-2">If you find any link on our Website that is offensive for any reason, you are free to contact and inform us any moment. We will consider requests to remove links but we are not obligated to or so or to respond to you directly.</p>
											<p className="mb-2">We do not ensure that the information on this website is correct, we do not warrant its completeness or accuracy; nor do we promise to ensure that the website remains available or that the material on the website is kept up to date.</p>
										</div>
									</div>
								</div>
								<div className="tab-pane fade " id="privacy" role="tabpanel" aria-labelledby="terms-tab">
									<div className="row">
										<div className="col-md-12">
											<div className="section-head style-1"><br />
												<h2 className="title">View Our Privacy Policy</h2>
											</div>
										</div>
										{/* <div className="col-md-3">
											<div className="dropdown float-end">
												  <button className="btn shadow-primary btn-primary policy-but" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
													Select Language
												  </button>
												  <ul className="dropdown-menu policy-drop" aria-labelledby="dropdownMenuButton1">
													<li><a className="dropdown-item" href="#">ENG</a></li>
													<li><a className="dropdown-item" href="#">FI</a></li>
													<li><a className="dropdown-item" href="#">GE</a></li>
												  </ul>
											</div>
										</div> */}
									</div>
									<div className="row justify-content-center">
										<div className="col-lg-12">
											<p className="mb-5">This privacy policy governs the manner in which Zwato collects, users, maintains and discloses information collected from user(s) of the website/application. This privacy policy applies to the website and all products and services offered by Zwato. For more details about us, refer the last section- "Contact us".</p>
											<h3>What information we collect?</h3>
											<p className="mb-2">We collect and proceed the following information:</p>
											<h6><strong>(a) Personal identification information</strong></h6>
											<p className="mb-2">We may collect personal identification information from users in a variety of ways, including, but not limited to, when users visit our website/application, subscribe to the newsletter, fill out a form, and be in contact with our activities, services, features or resources we make available on our website/application.</p>
											<p className="mb-2">Users may be asked for, as appropriate, name and surname, email address, phone number, user ID on Internet messengers (Skype etc.), name of the company which they represent, job position in the company, user feedback.</p>
											<p className="mb-4">Users may, however, visit our website anonymously. We will collect personal identification information from users only if they voluntarily submit such information to us. Users can always refuse to supply personal identification information, except that it may prevent them from engaging in certain site-related activities.</p>
											<h6><strong>(b) Non-personal identification information</strong></h6>
											<p className="mb-4">We may collect non-personal identification information about users whenever they interact with us through our website. Non-personal identification information may include the browser name, the type of computer and technical information about user’s means of connection to our site, such as the operating system and the internet service providers utilized and other similar information.</p>
											<h6><strong>(c) Web browser cookies</strong></h6>
											<p className="mb-5">Our website may use cookies to enhance user experience. A user's website browser places cookies on their hard drive for record-keeping purposes and sometimes to track information about them. User may choose to set their web browser to refuse cookies, or to alert them when cookies are being sent. If they do so, note that some parts of the website may not function properly.</p>
											<h3>What are the legal bases for procession of information?</h3>
											<p className="mb-2">We can process information on the following legal bases:</p>
											<h6><strong>(a) regarding personal identification information:</strong></h6>
											<ol className="mb-4 auto-number">
												<li>users consent (when user agrees to send the personal information);</li>
												<li>our legitimate interest, namely offering our services to the user or the company the user represents, negotiation and conclusion of respective contracts, and performance under such contracts;</li>
											</ol>
											<h6><strong>(b) regarding non-personal identification information and web browser cookies:</strong></h6>
											<ol className="mb-5 auto-number">
												<li>users consent;</li>
												<li>our legitimate interest, namely proper administration of our website.</li>
											</ol>
											<h3>How we use collected information</h3>
											<p className="mb-2">ZWATO INVOICING may collect and use a user’s personal information for following purposes:</p>
											<ul className="mb-5 auto-list">
												<li>To offer our services and products to the user or the company the user represents.</li>
												<li>To provide our services and products to the user or the company the user represents.</li>
												<li>To fulfill legal obligations based on agreements or other arrangements with the user or the company the user represents.</li>
												<li>To manage and coordinate our relationship with the user or the company the user represents.</li>
												<li>To personalize user experience: we may use information in the aggregate to understand how are users as a group can use the services and resource provided on our website/application.</li>
												<li>To improve our site: we may use the feedback users provide to improve our products and services.</li>
												<li>To run a promotion, contest, survey or any other website feature: to send users information they agreed to receive about topics we think will be of best interest to them.</li>
												<li>To send periodic emails: we may use the email address to respond to their enquiries, questions, and/or other requests. If a user decides to opt in to our mailing list, they will receive emails that may include company news, updates, related product or service information, etc. If at any time the user would like to unsubscribe from receiving any future emails, we include a detailed unsubscribe instruction at the bottom of each email.</li>
												<li>For any other legitimate business purposes. Information is collected only to the extent necessary for processing purposes.</li>
											</ul>
											<h3>What is the term of procession (storage) of information?</h3>
											<p className="mb-2">ZWATO INVOICING stores the information collected within the following terms:</p>
											<p className="mb-2">(a) Personal identification information- 10 years after the users last enquiry (in case no contact was conducted after the respective enquiry), or 10 years after the last contact with user or the company which they represent, terminates;</p>
											<p className="mb-5">(b) Non-personal identification information and web browser cookies- one year after the respective information was collected.</p>
											<h3>How we protect your information</h3>
											<p className="mb-5">We adopt appropriate data collection, storage and processing practices and security measures to protect against unauthorized access, alteration, disclosure or destruction of a user's personal information and data stored in our website/application.</p>
											<h3>Sharing your personal information</h3>
											<p className="mb-2">We do not sell, trade, or rent your personal identification information to others. We may share generic aggregated demographic information not linked to any personal identification information regarding visitors and users with our business partners, trusted affiliates and advertisers for the purposes outlined above. We may use third-party service providers to help us operate our business and the website or administer activities on our behalf, such as sending out newsletters or surveys. We may share your information with these third parties for those limited purposes provided that you have given us the permission.</p>
											<p className="mb-5">We reserve the right to provide your personal information inside our company.</p>
											<h3>The basic rights of users</h3>
											<p className="mb-2">The user has the following rights regarding information collected by us:</p>
											<ul className="mb-3 auto-list">
												<li>to request access to the information related to the user;</li>
												<li>to request rectification of the information related to the user;</li>
												<li>to request erasure of the information related to the user from our database;</li>
												<li>to cancel (withdraw) user's consent to the processing of the information related to the user;</li>
												<li>to request a restriction on the processing of the information related to the user;</li>
												<li>to request a transfer of the information related to the user to another controller;</li>
												<li>to object to the processing of information related to the user for direct marketing.</li>
											</ul>
											<p className="mb-2">If we, upon the user's request, erase information related to them from our database, we will keep the information that may be necessary for protecting our legitimate interests or for public authorities.</p>
											<p className="mb-2">If the user requires us to transfer information related to them to another controller, rectify it or provide access to it, we may first require further information in order to verify user’s identity.</p>
											<p className="mb-5">If the user is one of the opinion that his/her rights related to the protection of information related to them were breached, the user may appeal to us or submit a complaint to the relevant authority for the protection of personal data in the user's respective country (the country of user’s residence).</p>
											<h3>Changes to this privacy policy</h3>
											<p className="mb-2">Zwato. has the discretion to update this privacy policy at any time. When we do, we will revise the updated date at the bottom of this page. We encourage every user to frequently check the space for any changes to stay informed about how we are helping to protect the personal information we collect.</p>
											<p className="mb-5">You acknowledge and agree that it is your responsibility to review this privacy policy periodically and become aware of the followed modifications.</p>
											<h3>Your acceptance of these terms</h3>
											<p className="mb-2">By using this website/application and sharing your contact details with Zwato, you signify your acceptance to this Privacy Policy.</p>
											<p className="mb-2">The website/application is available only to individuals who are at least 16 years' old. By using this website/application you agree that you are at least 16 years old. If you do not agree to this Privacy Policy, please do not use our website/application. Your continued use of this website/application following the posting of changes to this Policy will be deemed to your acceptance of those changes.</p>
											<p className="mb-5">Your acceptance of these terms</p>
											<h3>Contact Us</h3>
											<p className="mb-5">If you have any questions about this Privacy Policy, the practices of this website/application or your dealings with the website/application please contact us at:</p>
											<blockquote className="block-quote style-1">
												<p className="mb-1"><strong>Zwato.</strong></p>
														<p className="mb-1"><strong>Level 1, Pine Valley, Embassy Golf Links, IRR, Bengaluru- 560071</strong></p>
														<p className="mb-1"><strong>Call us: +358753250980</strong></p>
														<p className="mb-1"><strong>Email us: info@zwato.com</strong></p>
											</blockquote>
										</div>
									</div>
								</div>
								
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	</div>	
</div>
</>
    );
};
export default TermsConditions;