import React, {useEffect} from "react";
import SimpleBar from 'simplebar-react';
import { Link } from 'react-router-dom';
import $ from "jquery";
import jQuery from "jquery";
import 'simplebar-react/dist/simplebar.min.css';

function CustomJs () {
  
    useEffect(() => {
      $('.btn02').click(function() {
        $('.drop-down14').show("slow");
       // $('.drop-down14').animate({top: "70px"}, 200);
     });
  
      $('h3.nav02').click(function() {
        $('.drop-down14').hide("slow");
       // $('.drop-down14').animate({bottom: "100%"}, 200);
       // $('.drop-down14').animate({top: "auto"}, 200);
      });
      
      $(".toggle-nav").on('click', function() {
        $("#sidebar-links .nav-menu").css("left", "0px");
      });
      $(".mobile-back").on('click', function() {
        $("#sidebar-links .nav-menu").css("left", "-410px");
      });
      
      $(".page-wrapper").attr("class", "page-wrapper " + localStorage.getItem('page-wrapper'));
      if (localStorage.getItem("page-wrapper") === null) {
          $(".page-wrapper").addClass("compact-wrapper");
      }
      
      // left sidebar and vertical menu
      if ($("#pageWrapper").hasClass("compact-wrapper")) {
        $(".sidebar-title").append(
          '<div class="according-menu"><i class="fa fa-angle-right"></i></div>'
        );
        $(".sidebar-title").on('click', function() {
          $(".sidebar-title")
            .removeClass("active")
            .find("div")
            .replaceWith(
              '<div class="according-menu"><i class="fa fa-angle-right"></i></div>'
            );
          $(".sidebar-submenu, .menu-content").slideUp("normal");
          $(".menu-content").slideUp("normal");
          if ($(this).next().is(":hidden") === true) {
            $(this).addClass("active");
            $(this)
              .find("div")
              .replaceWith(
                '<div class="according-menu"><i class="fa fa-angle-down"></i></div>'
              );
            $(this).next().slideDown("normal");
          } else {
            $(this)
              .find("div")
              .replaceWith(
                '<div class="according-menu"><i class="fa fa-angle-right"></i></div>'
              );
          }
        });
        $(".sidebar-submenu, .menu-content").hide();
        $(".submenu-title").append(
          '<div class="according-menu"><i class="fa fa-angle-right"></i></div>'
        );
        $(".submenu-title").on('click', function() {
          $(".submenu-title")
            .removeClass("active")
            .find("div")
            .replaceWith(
              '<div class="according-menu"><i class="fa fa-angle-right"></i></div>'
            );
          $(".submenu-content").slideUp("normal");
          if ($(this).next().is(":hidden") === true) {
            $(this).addClass("active");
            $(this)
              .find("div")
              .replaceWith(
                '<div class="according-menu"><i class="fa fa-angle-down"></i></div>'
              );
            $(this).next().slideDown("normal");
          } else {
            $(this)
              .find("div")
              .replaceWith(
                '<div class="according-menu"><i class="fa fa-angle-right"></i></div>'
              );
          }
        });
        $(".submenu-content").hide();
      } else if ($("#pageWrapper").hasClass("horizontal-wrapper")) {
        $(window).on("load", function () {
          $(document).on("load", ($(window).bind("resize", checkPosition)));
          function checkPosition() {
            if (window.matchMedia("(max-width: 991px)").matches) {
              $("#pageWrapper")
                .removeClass("horizontal-wrapper")
                .addClass("compact-wrapper");
              $(".page-body-wrapper")
                .removeClass("horizontal-menu")
                .addClass("sidebar-icon");
              $(".submenu-title").append(
                '<div class="according-menu"><i class="fa fa-angle-right"></i></div>'
              );
              $(".submenu-title").on('click', function() {
                $(".submenu-title").removeClass("active");
                $(".submenu-title")
                  .find("div")
                  .replaceWith(
                    '<div class="according-menu"><i class="fa fa-angle-right"></i></div>'
                  );
                $(".submenu-content").slideUp("normal");
                if ($(this).next().is(":hidden") === true) {
                  $(this).addClass("active");
                  $(this)
                    .find("div")
                    .replaceWith(
                      '<div class="according-menu"><i class="fa fa-angle-down"></i></div>'
                    );
                  $(this).next().slideDown("normal");
                } else {
                  $(this)
                    .find("div")
                    .replaceWith(
                      '<div class="according-menu"><i class="fa fa-angle-right"></i></div>'
                    );
                }
              });
              $(".submenu-content").hide();
      
              $(".sidebar-title").append(
                '<div class="according-menu"><i class="fa fa-angle-right"></i></div>'
              );
              $(".sidebar-title").on('click', function() {
                $(".sidebar-title").removeClass("active");
                $(".sidebar-title")
                  .find("div")
                  .replaceWith(
                    '<div class="according-menu"><i class="fa fa-angle-right"></i></div>'
                  );
                $(".sidebar-submenu, .menu-content").slideUp("normal");
                if ($(this).next().is(":hidden") === true) {
                  $(this).addClass("active");
                  $(this)
                    .find("div")
                    .replaceWith(
                      '<div class="according-menu"><i class="fa fa-angle-down"></i></div>'
                    );
                  $(this).next().slideDown("normal");
                } else {
                  $(this)
                    .find("div")
                    .replaceWith(
                      '<div class="according-menu"><i class="fa fa-angle-right"></i></div>'
                    );
                }
              });
              $(".sidebar-submenu, .menu-content").hide();
            }
          }
        });
      } else if ($("#pageWrapper").hasClass("compact-sidebar")) {
        var contentwidth = $(window).width();
        if (contentwidth > 992) {
          $('<div class="bg-overlay1"></div>').appendTo($("body"));
        }
      
        $(".sidebar-title").on('click', function() {
          $(".sidebar-title").removeClass("active");
          $(".bg-overlay1").removeClass("active");
          $(".sidebar-submenu").removeClass("close-submenu").slideUp("normal");
          $(".sidebar-submenu, .menu-content").slideUp("normal");
          $(".menu-content").slideUp("normal");
      
          if ($(this).next().is(":hidden") === true) {
            $(this).addClass("active");
            $(this).next().slideDown("normal");
            $(".bg-overlay1").addClass("active");
      
            $(".bg-overlay1").on('click', function() {
              $(".sidebar-submenu, .menu-content").slideUp("normal");
              $(this).removeClass("active");
            });
          }
          if (contentwidth < "992") {
            $(".bg-overlay").addClass("active");
          }
        });
        $(".sidebar-submenu, .menu-content").hide();
        $(".submenu-title").append(
          '<div class="according-menu"><i class="fa fa-angle-right"></i></div>'
        );
        $(".submenu-title").on('click', function() {
          $(".submenu-title")
            .removeClass("active")
            .find("div")
            .replaceWith(
              '<div class="according-menu"><i class="fa fa-angle-right"></i></div>'
            );
          $(".submenu-content").slideUp("normal");
          if ($(this).next().is(":hidden") === true) {
            $(this).addClass("active");
            $(this)
              .find("div")
              .replaceWith(
                '<div class="according-menu"><i class="fa fa-angle-down"></i></div>'
              );
            $(this).next().slideDown("normal");
          } else {
            $(this)
              .find("div")
              .replaceWith(
                '<div class="according-menu"><i class="fa fa-angle-right"></i></div>'
              );
          }
        });
        $(".submenu-content").hide();
      
        $(".sidebar-wrapper nav").find("a").removeClass("active");
        $(".sidebar-wrapper nav").find("li").removeClass("active");
      
        var current = window.location.pathname;
        $(".sidebar-wrapper nav ul>li a").each(function () {
          var link = $(this).attr("href");
          if (link && current.indexOf(link) !== -1) {
            $(this).parents().children("a").addClass("active");
            $(this)
              .parents()
              .parents()
              .children(".nav-sub-childmenu")
              .css("display", "block");
            $(this).addClass("active");
            $(this)
              .parent()
              .parent()
              .parent()
              .children("a")
              .find("div")
              .replaceWith('<div class="according-menu"><i class="fa fa-angle-down"></i></div>');
          }
        });

      }
      
      // toggle sidebar
      var $nav = $(".sidebar-wrapper");
      var $header = $(".page-header");
      var $toggle_nav_top = $(".toggle-sidebar");
      $toggle_nav_top.on('click', function() {
        $nav.toggleClass("close_icon");
        $header.toggleClass("close_icon");
        $(window).trigger("overlay");
      });
      
      $(window).on("overlay", function () {
        var $bgOverlay = $(".bg-overlay");
        var $isHidden = $nav.hasClass("close_icon");
        if ($(window).width() <= 991 && !$isHidden && $bgOverlay.length === 0) {
          $('<div class="bg-overlay active"></div>').appendTo($("body"));
        }
      
        if ($isHidden && $bgOverlay.length > 0) {
          $bgOverlay.remove();
        }
      });
      
      $(".sidebar-wrapper .back-btn").on('click', function(e) {
        $(".page-header").toggleClass("close_icon");
        $(".sidebar-wrapper").toggleClass("close_icon");
        $(window).trigger("overlay");
      });
      
      $("body").on("click", ".bg-overlay", function () {
        $header.addClass("close_icon");
        $nav.addClass("close_icon");
        $(this).remove();
      });

      
var $body_part_side = $(".body-part");
$body_part_side.on('click', function() {
  $toggle_nav_top.attr("checked", false);
  $nav.addClass("close_icon");
  $header.addClass("close_icon");
});
var $window = $(window);
var widthwindow = $window.width();
(function ($) {
  if (widthwindow <= 1385) {
    $toggle_nav_top.attr("checked", false);
    $nav.addClass("close_icon");
    $header.addClass("close_icon");
  }
})(jQuery);
var view = $("#sidebar-menu");
var move = "500px";
var leftsideLimit = -500;

var getMenuWrapperSize = function () {
  return $(".sidebar-wrapper").innerWidth();
};
var menuWrapperSize = getMenuWrapperSize();

var sliderLimit; // Declare the variable outside of the conditional blocks

if (menuWrapperSize >= 1660) {
  sliderLimit = -3000;
} else if (menuWrapperSize >= 1440) {
  sliderLimit = -3600;
} else {
  sliderLimit = -4200;
}

$("#left-arrow").addClass("disabled");
$("#right-arrow").on('click', function() {
  var currentPosition = parseInt(view.css("marginLeft"));
  if (currentPosition >= sliderLimit) {
    $("#left-arrow").removeClass("disabled");
    view.stop(false, true).animate(
      {
        marginLeft: "-=" + move,
      },
      {
        duration: 400,
      }
    );
    if (currentPosition === sliderLimit) {
      $(this).addClass("disabled");
    }
  }
});


$("#left-arrow").on('click', function() {
  var currentPosition = parseInt(view.css("marginLeft"));
  if (currentPosition < 0) {
    view.stop(false, true).animate(
      {
        marginLeft: "+=" + move,
      },
      {
        duration: 400,
      }
    );
    $("#right-arrow").removeClass("disabled");
    $("#left-arrow").removeClass("disabled");
    if (currentPosition >= leftsideLimit) {
      $(this).addClass("disabled");
    }
  }
});


// if ($('#pageWrapper').hasClass('compact-wrapper')) {
//   $(".sidebar-wrapper nav #sidebar-menu .simplebar-wrapper .simplebar-content-wrapper .simplebar-content").find("a").removeClass("active");
//   $(".sidebar-wrapper nav #sidebar-menu .simplebar-wrapper .simplebar-content-wrapper .simplebar-content").find("li").removeClass("active");

//   var current = window.location.pathname
//   $(".sidebar-wrapper nav #sidebar-menu ul .simplebar-mask li a").filter(function () {

//       var link = $(this).attr("href");
//       if (link) {
//           if (current.indexOf(link) != -1) {
//               $(this).parents().children('a').addClass('active');
//               $(this).parents().parents().children('ul').css('display', 'block');
//               $(this).addClass('active');
//               $(this).parent().parent().parent().children('a').find('div').replaceWith('<div class="according-menu"><i class="fa fa-angle-down"></i></div>');
//               $(this).parent().parent().parent().parent().parent().children('a').find('div').replaceWith('<div class="according-menu"><i class="fa fa-angle-down"></i></div>');
//               return false;
//           }
//       }
//   });
// }
$(window).resize(function () {	
  var widthwindaw = $window.width();	
  if (widthwindaw <= 1400) {	
    $toggle_nav_top.attr("checked", false);	
    $nav.addClass("close_icon");	
    $header.addClass("close_icon");	
    // alert("1");	
  } 	
  else if (widthwindaw => 1400) {	
    $toggle_nav_top.attr("checked", true);	
    $nav.removeClass("close_icon");	
    $header.removeClass("close_icon");	
    // alert(" else if ");	
  }	
});
$(".dropdown-basic .dropdown").hover(function(){ 
      $(this).parent().closest(".table-responsive").css( "overflow", "inherit" );    
    }, function(){      
      $(this).parent().closest(".table-responsive").css( "overflow", "auto" );
  });


});
    let publicUrl = process.env.PUBLIC_URL + '/'
    return (
        <>
            
            <div className="sidebar-wrapper">
                <div className="logo-wrapper"><Link to="/Dashboard"><img className="img-fluid for-light logo1" src={publicUrl+"assets/images/logo-zwato.png"} alt=""/></Link>
                  <div className="back-btn"><i className="fa fa-angle-left"></i></div>  
                  <div className="toggle-sidebar"><i className="status_toggle middle sidebar-toggle fa-solid fa-bars-staggered"> </i></div>
                
                </div>
                <div className="logo-icon-wrapper"><Link to="/Dashboard"><img className="img-fluid for-dark" src={publicUrl+"assets/images/logo-icon.png"} alt=""/></Link></div>
                <nav className="sidebar-main">
                  <div className="left-arrow" id="left-arrow"><i data-feather="arrow-left"></i></div>
                  <div id="sidebar-menu">
                    
                    <ul className="sidebar-links">
                    <SimpleBar>
                      <li className="back-btn"><Link to="/Dashboard"><img className="img-fluid for-dark" src={publicUrl+"assets/images/logo-icon.png"} alt=""/></Link>
                        <div className="mobile-back text-end"><span>Back</span><i className="fa fa-angle-right ps-2" aria-hidden="true"></i></div>
                      </li>
                      <li className="sidebar-list">           
                        <Link className="sidebar-link sidebar-title link-nav" to="/Dashboard"><i className="fa-regular fa-house f-16 m-r-10"></i><span>Dashboard</span></Link>
                      </li>
                      <li className="sidebar-list"><Link className="sidebar-link sidebar-title" to="#"><i className="fa-regular fa-user f-16 m-r-10"></i><span>Customers</span></Link>
                        <ul className="sidebar-submenu">
                          <li><Link to="/Viewcustomer">Customer List</Link></li>
                          <li><Link to="/Addnewcustomer">Single Customer</Link></li>
                          <li><Link to="/ImportCustomer">Import Customer</Link></li>
                        </ul>
                      </li>
                      <li className="sidebar-list"><Link className="sidebar-link sidebar-title" to="#"><i className="fa-brands fa-buffer f-16 m-r-10"></i><span>Products                </span></Link>
                        <ul className="sidebar-submenu">
                          <li><Link to="/NewProduct">Add Products</Link></li>
                          <li><Link to="/ImportProduct">Import Products</Link></li>
                        </ul>
                      </li>
                      <li className="sidebar-list"><Link className="sidebar-link sidebar-title" to="#"><i className="fa-regular fa-file-invoice-dollar f-16 m-r-10"></i><span>Invoices</span></Link>
                        <ul className="sidebar-submenu">
                          <li><Link to="/Invoices">View Invoice</Link></li>
                          <li><Link to="/CreateInvoice">Create Invoice</Link></li>
                          <li><Link to="/ImportInvoice">Import Invoice</Link></li>
                          <li><Link to="/OpenInvoice">Open Invoice</Link></li>
                        </ul>
                      </li>
                      <li className="sidebar-list"><Link className="sidebar-link sidebar-title" to="#"><i className="fa-light fa-file-invoice f-16 m-r-10"></i><span>Estimates</span></Link>
                        <ul className="sidebar-submenu">
                          <li><Link to="/Estimate">View Estimates</Link></li>
                          <li><Link to="/CreateEstimate">Create Estimates</Link></li>
                          <li><Link to="/ImportEstimate">Import Estimates</Link></li>
                        </ul>
                      </li>
                      {/* <li className="sidebar-list"><Link className="sidebar-link sidebar-title" to="#"><i className="fa-solid fa-object-exclude f-16 m-r-5"></i><span>Your Places</span></Link>
                        <ul className="sidebar-submenu">
                          <li><Link to="#">Download Receipt</Link></li>
                          <li><Link to="/MyWallet">Your Wallet</Link></li>
                          <li><Link to="#">Reports</Link></li>
                        </ul>
                      </li> */}
                      <li className="sidebar-list"><Link className="sidebar-link sidebar-title" to="#"><i className="fa-regular fa-user-gear f-16 m-r-5"></i><span>Accounts</span></Link>
                        <ul className="sidebar-submenu">
                          <li><Link to="/MyAccount">My Accounts</Link></li>
                          <li><Link to="/TradeNameList">Trade Name List</Link></li>
                          <li><Link to="/TradeName">Add Trade Name</Link></li>
                          <li><Link to="/BankDetails">Bank Account</Link></li>
                          <li><Link to="/price">Pricing</Link></li>
                        </ul>
                      </li>
                      <li className="sidebar-list"><Link className="sidebar-link sidebar-title" to="#"><i className="fa-solid fa-calendar-lines-pen f-16 m-r-5"></i><span>Calendar</span></Link>
                        <ul className="sidebar-submenu">                          
                          <li><Link to="/MyCalendar">Add/Edit Event</Link></li>
                          <li><Link to="/MyCalendarList">Events List</Link></li>
                        </ul>
                      </li>
                      <li className="sidebar-list"><Link className="sidebar-link sidebar-title" to="#"><i className="fa-solid fa-calendar-circle-user f-16 m-r-5"></i><span>Payrolls</span></Link>
                        <ul className="sidebar-submenu">
                          <li><Link to="/AddEmployee">Employee</Link></li>
                          <li><Link to="/ViewEmploye">View Employee</Link></li>
                          <li><Link to="/AddPayroll">Create Payroll</Link></li>
                          <li><Link to="/Payrolllist">Payroll Details</Link></li>
                        </ul>
                      </li>
                      <li className="sidebar-list"><Link className="sidebar-link sidebar-title" to="#"><i className="fa-sharp fa-solid fa-headset f-16 m-r-5"></i><span>Support</span></Link>
                        <ul className="sidebar-submenu">
                          <li><Link to="OpenTicket">Open Ticket</Link></li>
                          <li><Link to="ViewTicket">View Ticket</Link></li>
                        </ul>
                      </li>

                      <li className="sidebar-list"><Link className="sidebar-link sidebar-title link-nav" to="/MyWallet"><i className="fa-sharp fas fa-tags f-16 m-r-5"></i><span>My Wallet</span></Link>
                        {/* <ul className="sidebar-submenu">
                          <li><Link to="OpenTicket">Open Ticket</Link></li>
                          <li><Link to="ViewTicket">View Ticket</Link></li>
                        </ul> */}
                      </li>

                      <li className="sidebar-list">           
                        <Link className="sidebar-link sidebar-title link-nav" to="/reports"><i className="fa-regular fa-file f-16 m-r-10"></i><span>Reports</span></Link>
                      </li>
                      
                      <li className="sidebar-list"><Link className="sidebar-link sidebar-title" to="#"><i className="fa-sharp fa-solid fa-file f-16 m-r-5"></i><span>Documents</span></Link>
                        <ul className="sidebar-submenu">
                          <li><Link to="/LetterHead">Letter head</Link></li>
                          <li><Link to="/Offerletterlist">Offerletter list</Link></li>
                          <li><Link to="/Offerletter">Create Offer letter</Link></li>
                          {/* <li><Link to="/Editofferletter">Editoffer letter</Link></li> */}                          
                          <li><Link to="/JoiningLetterList">Joining Letter List</Link></li>
                          <li><Link to="/Joiningletter">Create Joining letter </Link></li>
                        </ul>
                      </li>

                    </SimpleBar>
                    </ul>
                  </div>
                  <div className="right-arrow" id="right-arrow"><i data-feather="arrow-right"></i></div>
                </nav>
              </div>
        </>
    )
};
export default CustomJs;