import React, {useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom';
import $ from "jquery";
import Cookies from 'js-cookie';
import swal from "sweetalert2";
import { API_BASE_URL, MEDIA_BASE_URL, BASE_URL } from "../../config/config";
import { encrypt, decrypt } from '../../config/encrypt-decrypt';

function PayNow () {

//////////////////////////////////////////////////////Get Company Details //////////////////////
const [companydetails, setCompanyDetails] = useState([]);
async function fetchcompanydetails(regno) {
	try {
	  const response = await fetch(`${API_BASE_URL}/api/getCompanyPayment?registrationid=${regno}`, {
		method: 'GET',
		headers: {
		 // Authorization: Cookies.get("access_token"),
		  'Content-Type': 'application/json; charset=utf-8'
		},
		// body: JSON.stringify({
		//   registrationid: Cookies.get("registrationid")       
		// })
	  });
	  
	  const data = await response.json();
	  debugger;
	  setCompanyDetails(data.d);
	 
    $("#user_name").val(data.d.customername);


  
	} catch (error) {
	  console.log("Test Error");
	}
  }

  //////////////////////////////////////////////////////Get Invoice Details //////////////////////
const [invoicedetails, setInvoiceDetails] = useState([]);
const [invoiceproduct, setInvoiceProduct] = useState([]);
const [returnurl, setReturnUrl] = useState("");
async function fetchinvoicedetails(invoiceno,regno) {
	debugger;
	try {
	  const response = await fetch(`${API_BASE_URL}/invoice/GetInvoiceDetailsPayment?registrationid=${regno}&invoiceno=${invoiceno}`, {
		method: 'GET',
		headers: {
		 // Authorization: Cookies.get("access_token"),
		  'Content-Type': 'application/json; charset=utf-8'
		},
		// body: JSON.stringify({
		//   registrationid: Cookies.get("registrationid")       
		// })
	  });	  
	  const data = await response.json();
	  debugger;
	  setInvoiceDetails(data.d);
	  setInvoiceProduct(data.prod);
    var amount="";
    amount=(parseFloat(data.d.totalamount)-parseFloat(data.d.paidamount));
    setReturnUrl(`https://app.zwato.com/PaymentResponse/?invoiceno=${encrypt(data.d.invoiceno)}&regno=${encrypt(data.d.registrationid)}&amount=${amount}&cstmrid=${encrypt(data.d.customerid)}&rfncno=${encrypt(data.d.referenceno)}`);
    
	} catch (error) {
	  console.log("Test Error");
	}
  }
  const [viewinvoice, setViewInvoice] = useState("");
  useEffect(() => {
	let search = window.location.search;
  let params = new URLSearchParams(search);
	let decinvoiceno = decrypt(params.get('invoiceno'));
	let decregno = decrypt(params.get('regno'));
  let tempid=params.get("tempid");
	let invoiceno = params.get('invoiceno');
	let regno = params.get('regno');
  fetchcompanydetails(decregno);  
  fetchinvoicedetails(decinvoiceno, decregno);
  setViewInvoice(`<a href="https://zwato.com/re_invoice/?tempid=${tempid}&invoiceno=${decinvoiceno}&regno=${decregno}&language=English" class="btn btn-square btn-light btn-xs f-12 text-danger" target="_blank">Click to View Invoice</a>`);

  },[]);
  
  
  



    return (
<>
<form id="form1" runat="server" action="https://softatechnologies.com/paymenygateway/visma-pay-php-lib-accninvoice/example/index.php" method="post">

        <input type="hidden" name="resturan_name" id="resturan_name" value="" />
        <input type="hidden" name="user_name" id="user_name" value={invoicedetails &&(invoicedetails.customername)} />
        <input type="hidden" name="order_id" id="order_id" value={invoicedetails &&(invoicedetails.referenceno)} />
        <input type="hidden" name="amount" id="amount" value={invoicedetails &&((parseFloat(invoicedetails.totalamount)-parseFloat(invoicedetails.paidamount)))} />
        <input type="hidden" name="session_id" id="session_id" value={invoicedetails &&(invoicedetails.invoiceno)} />
        <input type="hidden" name="url" id="url" value={returnurl} />
         

		<div className="login-8">
                <div className="container">
                    <div className="row justify-content-center">
                        
                        <div className="col-lg-10 col-md-10">
                            <div className="row login-box p-3">
                                <div className="col-lg-3 col-md-3">                                    
                                    <img src={companydetails.photo && (companydetails.photo)} className="img-fluid"/> 
                                </div>
                                <div className="col-lg-9 col-md-9">                                    
                                    <h3 class="">Pay Your Bill Now</h3>
                                    <p className="text-danger"><small>Please check below details before pay</small></p>
                                    <div id="paymentbox" runat="server">
                                        <div className="table-responsive">
                                            <table className="table">
                                                <tbody>
                                                    <tr>
                                                        <td><strong>Customer Name :</strong> <span className="dsw">
                                                        {invoicedetails &&(invoicedetails.customername)}</span></td>
                                                        <td><strong>Invoice Number : </strong><span className="dsw">
									                    {invoicedetails &&(invoicedetails.invoiceno)}</span></td>
                                                    </tr>
                                                    <tr>
                                                        <td><strong>Invoice Date : </strong><span className="dsw">
                                                        {invoicedetails &&(window.getDateStringwithtime(invoicedetails.invoicedate))}</span></td>
                                                        <td><strong>Due Date : </strong><span className="dsw">
                                                        {invoicedetails &&(window.getDateStringwithtime(invoicedetails.duedate))}</span></td>
                                                    </tr>
                                                    <tr>
                                                        <td><strong>Due Amount : </strong><span className="dsw">
                                                        {invoicedetails &&((parseFloat(invoicedetails.totalamount)-parseFloat(invoicedetails.paidamount)))}</span></td>
                                                        <td dangerouslySetInnerHTML={{ __html: viewinvoice}}></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>  
                                    
                                    <br/>
                                    <button type="submit" class="btn btn-primary btn-custom" fdprocessedid="cmghih">Confirm</button>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>


    <div>

       

    </div>
</form>
</>
    );
};
export default PayNow;