import React, { useEffect } from "react";
import { lazy, Suspense } from 'react';
import { StrictMode } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import $ from "jquery";
import { API_BASE_URL, MEDIA_BASE_URL, BASE_URL } from "./config/config";
import ProtectedRoutes from './routes/ProtectedRoutes'; //Authenticated routes
import PublicRoute from './routes/PublicRoute'; 
import PrivateRoute from './routes/PrivateRoute'; 
import MainHeader from "./components/global-component/MainHeader";
import CustomJs from "./components/global-component/CustomJs";
import ChatSec from "./components/global-component/ChatSec";
import Footer from "./components/global-component/Footer";
import Loader from "./components/global-component/Loader";
import SignIn from "./components/section-component/SignIn";
import RegisterNow from "./components/section-component/RegisterNow";
import VerifyEmail from "./components/section-component/VerifyEmail";
import ResetPassword from "./components/section-component/ResetPassword";
import LoginMicrosoft from "./components/section-component/LoginMicrosoft";
import SessionTimeout from './components/global-component/SessionTimeout';
import PayNow from "./components/section-component/PayNow";
import PaymentResponse from "./components/section-component/PaymentResponse";
import TermsConditions from "./components/section-component/TermsConditions";

import Cookies from 'js-cookie';

const NoFoundComponent = lazy(() => import('./routes/NoFoundComponent'));


function App() {

  //const cookies = new Cookies();
  let isAuthenticated1 = document.cookie.includes('access_token');
  let isAuthenticated=false;
 
  if(isAuthenticated1){

    if (localStorage.hasOwnProperty("email")) {
      isAuthenticated=true;
   
     // isAuthenticated=true;
    }
  }

  //api call karna hain cookie se access token le ke
let config = {
  
  method: 'post',
  maxBodyLength: Infinity,
  headers: {
    'Content-Type': 'application/json; charset=utf-8'       
  },
  body :JSON.stringify({registrationid:Cookies.get("registrationid")})
};

//fetch(`${API_BASE_URL}/userRegistration/login`, config)
fetch(`${API_BASE_URL}/userRegistration/login`, config)
.then(response => {
  if (!response.ok) {
    // throw new Error('Network response was not ok');
  }
  return response.json(); // Extract the response body as JSON
})
.then(data => {  
  if(data.err && data.err.name && data.err.name==="TokenExpiredError" && !sessionStorage.getItem('reloadPage'))
{
  sessionStorage.setItem('reloadPage','true');
  isAuthenticated=false;
  localStorage.removeItem("email");
  window.location.href="/dashboard";
}else{
  sessionStorage.removeItem('reloadPage');
  isAuthenticated=true;
}
});


  const handleClick = () => {
    //setAuth(!isAuthenticated);
  }





  useEffect(() => {
  
    
    $("#chat-side1").hide();
    $("#main-1").animate({width:"100%"});
    $("#showhidesidebar").html("<i class='fa-regular fa-network-wired'></i>");
    $("#maxImize").click(function(){
      var inputValue=$("#maxImize").attr('value');
      if(inputValue==="Expand") {
        $("#chat-side1").animate({width:"40%"});
        $("#main-1").animate({width:"60%"});
        $("#maxImize").attr('value','Reduce');
      } else if(inputValue==="Reduce") {
        $("#chat-side1").animate({width:"30%"});
        $("#main-1").animate({width:"70%"});
        $("#maxImize").attr('value','Expand');
      }
    });
    $("#showhidesidebar").click(function(){
      var inputValue=$("#showhidesidebar").attr('value');
      if(inputValue==="Show") {
        $("body").addClass("slideclass");
        $("#showhidesidebar").html("<i class='fa-regular fa-network-wired'></i>");
        $("#chat-side1").show();
        $("#chat-side1").animate({width:"30%"});
        $("#main-1").animate({width:"70%"});
        $("#showhidesidebar").attr('value','Hide');
      } else if(inputValue==="Hide") {
        $("body").removeClass("slideclass");
        $("#showhidesidebar").html("<i class='fa-regular fa-network-wired'></i>");
        $("#chat-side1").hide();
        $("#chat-side1").animate({width:"0%"});
        $("#main-1").animate({width:"100%"});
        $("#showhidesidebar").attr('value','Show');
      }
    });
    $("#CloseSidebar").click(function(){
      $("body").removeClass("slideclass");
      $("#chat-side1").hide();
      $("#showhidesidebar").html("<i class='fa-regular fa-network-wired'></i>");
      $("#chat-side1").animate({width:"0%"});
      $("#main-1").animate({width:"100%"});
      $("#showhidesidebar").attr('value','Show');
    });
  }, []);

 

return (
<>


  <div className="App">
  <SessionTimeout isAuthenticated={isAuthenticated} logOut={handleClick} />
    <Router>
      <Suspense fallback={<SignIn />}>
        <Switch>
          <PublicRoute
            path="/SignIn"
            isAuthenticated={isAuthenticated}
          >
            <SignIn />
          </PublicRoute>
          <PublicRoute
            path="/RegisterNow"
            isAuthenticated={isAuthenticated}
          >
            <RegisterNow />
          </PublicRoute>
          <PublicRoute
            path="/VerifyEmail"
            isAuthenticated={isAuthenticated}>
            <VerifyEmail />
          </PublicRoute>
          <PublicRoute
            path="/ResetPassword"
            isAuthenticated={isAuthenticated}>
            <ResetPassword />
          </PublicRoute>
        
          <PublicRoute
            path="/LoginMicrosoft"
            isAuthenticated={isAuthenticated}>
            <LoginMicrosoft />
          </PublicRoute>

          <PublicRoute
            path="/PayNow"
            isAuthenticated={false}>
            <PayNow />
          </PublicRoute>
          <PublicRoute
            path="/TermsConditions"
            isAuthenticated={false}>
            <TermsConditions />
          </PublicRoute>

          <PublicRoute
            path="/PaymentResponse"
            isAuthenticated={false}>
            <PaymentResponse />
          </PublicRoute>

          
          

          <PrivateRoute
            path="/"
            isAuthenticated={isAuthenticated}
          >
            <>
             <Loader />
            <div className="flexbox">
              <article className="main" id="main-1">
                <div className="page-wrapper compact-wrapper" id="pageWrapper">
                 <MainHeader />
                  <div className="page-body-wrapper">
                    <CustomJs />
                    <div className="page-body">
                      <ProtectedRoutes />
                    </div>
                     <Footer />
                  </div>
                </div>
              </article>
              
                <ChatSec />
              
            </div>
            </>
          </PrivateRoute>
          <Route path="*">
            <NoFoundComponent />
          </Route>
        </Switch>
      </Suspense>
     
    </Router>
  </div>
  </>
);
};

export default App;