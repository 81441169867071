import React, {useState, useEffect} from "react";
import { Link } from 'react-router-dom';
import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { useCookies } from 'react-cookie';
import $ from 'jquery';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BsFillEyeFill, BsFillEyeSlashFill } from 'react-icons/bs';
import Loader1 from '../global-component/Loader1';
import swal from "sweetalert2";
import { GoogleLogin } from '@react-oauth/google';
import { googleLogout, useGoogleLogin } from '@react-oauth/google';
import axios from 'axios';
import { API_BASE_URL } from "../../config/config";
import CountriesRegister from '../global-component/CountriesRegister';

function RegisterNow () {

<ToastContainer
position="top-right"
autoClose={5000}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover
theme="light"
//transition: Bounce,
/>
    const [showcurrency, setShowCurrency] = useState();
    const defaultFormFields = {
        registertype:'',
fname:'',
lname:'',
country:'',
countrycode:'',
phoneno:'',
email:'',
confirmemail:'',
companyname:'',
Currency:'',
password:'',
Confirm_password:'',
terms:false,
totalexperience:'',
timezone:'',
offset:'',
pkgid:'',

      };

    const [formFields, setFormFields] = useState(defaultFormFields);
    const {registertype,        
    fname,
    lname,
    country,
    countrycode,
    phoneno,
    email,
    confirmemail,
    companyname,
    Currency,
    password,
    Confirm_password,
    terms,
    totalexperience,
    timezone,
    offset,
    pkgid } = formFields;
    const [isChecked, setIsChecked] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const handleChange = (event) => {
        const { name, value } = event.target;    
        setFormFields({ ...formFields, [name]: value });
        if(name=="password")
            {    
                if(!isPasswordStrong(value))
                {
                    setErrorMessage('Password should contain at least one number, one uppercase, one lowercase, one special character and minimam 6 letter.');                
                }
                else{
                    setErrorMessage('');
                }     
    
                if(formFields.Confirm_password ==value)
                    {
                        $("#cnfrmpsmatch").html("<span class='fa fa-check'></span> Password matched");
                        $("#cnfrmpsmatch").css("color","green");
                        $("#cnfrmpsmatch").css("float","left");
                    }
                    else{
                        $("#cnfrmpsmatch").html("Password not Matched");
                        $("#cnfrmpsmatch").css("color","red");
                        $("#cnfrmpsmatch").css("float","left");
                    }
    
            }
    
            if(name =="confirmemail"){
                if(formFields.email ==value)
                    {
                        $("#cnfrmemailmatch").html("<span class='fa fa-check'></span> Email matched");
                        $("#cnfrmemailmatch").css("color","green");
                        $("#cnfrmemailmatch").css("float","left");
                    }
                    else{
                        $("#cnfrmemailmatch").html("Email not Matched");
                        $("#cnfrmemailmatch").css("color","red");
                        $("#cnfrmemailmatch").css("float","left");
                    }
            }

            if(name =="email"){
                if(formFields.confirmemail ==value)
                    {
                        $("#cnfrmemailmatch").html("<span class='fa fa-check'></span> Email matched");
                        $("#cnfrmemailmatch").css("color","green");
                        $("#cnfrmemailmatch").css("float","left");
                    }
                    else{
                        $("#cnfrmemailmatch").html("Email not Matched");
                        $("#cnfrmemailmatch").css("color","red");
                        $("#cnfrmemailmatch").css("float","left");
                    }
            }
    
    
            if(name =="Confirm_password"){
                if(formFields.password ==value)
                    {
                        $("#cnfrmpsmatch").html("<span class='fa fa-check'></span> Password matched");
                        $("#cnfrmpsmatch").css("color","green");
                        $("#cnfrmpsmatch").css("float","left");
                    }
                    else{
                        $("#cnfrmpsmatch").html("Password not Matched");
                        $("#cnfrmpsmatch").css("color","red");
                        $("#cnfrmpsmatch").css("float","left");
                    }
            }
    

      };

      const isPasswordStrong = (password) => {            
        // const number     = /([0-9])/;
        // const upperCase  = /([A-Z])/;
        // const lowerCase  = /([a-z])/;
        // const specialCharacters = /([~,!,@,#,$,%,^,&,*,-,_,+,=,?,>,<])/;
        // return number.test(password) && upperCase.test(password) && lowerCase.test(password) && specialCharacters.test(password);

        const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{6,}$/;
        return passwordRegex.test(password);
      };


    const handleChangeterm = (event) => {
        setIsChecked(event.target.checked);
        setFormFields({...formFields, [event.target.name]: event.target.checked});
       


    };
   useEffect(() => {
    $("#thanksubmit").hide();
        $("#formsubmit").show();  
    const queryParams = new URLSearchParams(window.location.search);
    const pckjid = queryParams.get('pkjid');
   // console.log(pckjid);
    fetchcurrentcountry();
  }, []);
  const [cookies, setCookie] = useCookies(['access_token', 'refresh_token']);
  const [apiKey, setApiKey] = useState('87d7263abc094f35a77b313760406905');
  const [flag, setFlag] = useState();
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const togglePasswordVisibilityConfirm = () => {
    setShowPasswordConfirm(!showPasswordConfirm);
  };


  async function fetchcurrentcountry(){
    fetch('https://api.ipify.org/?format=json')
    .then(response => response.json())
    .then(data =>getcountrydata(data.ip))
    .catch(error => console.error('Error fetching IP address:', error));
  };
  
async function getcountrydata(ip){
    fetch(`https://api.ipgeolocation.io/ipgeo?apiKey=${apiKey}&ip=${ip}`)
    .then(response => response.json())
    .then(data => showcountrydata(data))
    .catch(error => console.error('Error fetching IP details:', error));
}

async function showcountrydata(data){

setFormFields({...formFields, 
    Currency:data.currency.code,
    country:data.country_name,
    timezone:data.time_zone.name,
    offset:data.time_zone.offset
    });
    setShowCurrency(data.currency.code);
    let cntrycode2=data.country_code2.toLowerCase();
    setFlag(cntrycode2);
    $("#phonecode").html(data.calling_code);
    //console.log(data);

    localStorage.setItem('currentcountry', data.country_name);
    localStorage.setItem('currentcallingcode', data.calling_code);            
    localStorage.setItem('currentflag', cntrycode2);
    localStorage.setItem('currentcurrency', data.currency.code); 


}
  const handleSelect = (vcurrency,country,flag,code) => {  
    setFormFields({...formFields, 
    Currency:vcurrency,
    country:country
    });
    let flagvalue = flag.toLowerCase();
    setFlag(flagvalue);
    setShowCurrency(vcurrency);
    $("#phonecode").html(code);
  };


  const handlesubmit = async (event) => {
    event.preventDefault();
   
 if(formFields.registertype===""){
    swal.fire({
        title: '<span style="color:#f27474">Opps!</span>',
        html: "Please select register type! <br /><br /><a href='javascript:swal.close();' class='btn btn-primary btn-sm'>OK</a>",
        type: 'error',
        icon: "error",
        showCloseButton: true,
        showCancelButton: false,
        showConfirmButton: false
    });
      
    return; 
 }
 if(formFields.terms==false){
    swal.fire({
        title: '<span style="color:#f27474">Opps!</span>',
        html: "Please select terms and privacy policy <br /><br /><a href='javascript:swal.close();' class='btn btn-primary btn-sm'>OK</a>",
        type: 'error',
        icon: "error",
        showCloseButton: true,
        showCancelButton: false,
        showConfirmButton: false
    });
      
    return;    
 }

 if(formFields.email==formFields.confirmemail){
       
 }
 else{
    swal.fire({
        title: '<span style="color:#f27474">Opps!</span>',
        html: "Confirm email not matched <br /><br /><a href='javascript:swal.close();' class='btn btn-primary btn-sm'>OK</a>",
        type: 'error',
        icon: "error",
        showCloseButton: true,
        showCancelButton: false,
        showConfirmButton: false
    });
      
    return; 
 }

 if(formFields.password==formFields.Confirm_password){
       
 }
 else{
    swal.fire({
        title: '<span style="color:#f27474">Opps!</span>',
        html: "Confirm password not matched <br /><br /><a href='javascript:swal.close();' class='btn btn-primary btn-sm'>OK</a>",
        type: 'error',
        icon: "error",
        showCloseButton: true,
        showCancelButton: false,
        showConfirmButton: false
    });
      
    return; 
 }



 const axios = require('axios');

//  let datas = JSON.stringify({
//    "registerdata": {           
//     "registertype":formFields.registertype,
//     "fname":formFields.fname,
//     "lname":formFields.lname,
//     "country":formFields.country,
//     "countrycode":$("#phonecode").html(),
//     "phoneno":formFields.phoneno,
//     "email":formFields.email,
//     "confirmemail":formFields.confirmemail,
//     "companyname":formFields.companyname,
//     "Currency":formFields.Currency,
//     "password":formFields.password,
//     "Confirm_password":formFields.Confirm_password,
//     "terms":formFields.terms,
//     "totalexperience":formFields.totalexperience,
//     "timezone":formFields.timezone,
//     "offset":formFields.offset,
//     "pkgid":formFields.pkgid,
//    }
//  });
 let datas = JSON.stringify({    
     "registertype":formFields.registertype,
     "fname":formFields.fname,
     "lname":formFields.lname,
     "country":formFields.country,
     "countrycode":$("#phonecode").html(),
     "phoneno":formFields.phoneno,
     "email":formFields.email,
     "confirmemail":formFields.confirmemail,
     "companyname":formFields.companyname,
     "Currency":formFields.Currency,
     "password":formFields.password,
     "Confirm_password":formFields.Confirm_password,
     "terms":formFields.terms,
     "totalexperience":formFields.totalexperience,
     "timezone":formFields.timezone,
     "offset":formFields.offset,
     "pkgid":formFields.pkgid,
  });
 //console.log(datas);
 let config = {
   method: 'post',
   maxBodyLength: Infinity,
   headers: { 
     'Content-Type': 'application/json'
   },
   body : datas
 };

 //"url": "/userRegistration/APISaveRegister",
 fetch(`${API_BASE_URL}/userRegistration/APISaveRegister`, config)
 .then(response => {
     if (!response.ok) {
      // throw new Error('Network response was not ok');
     }
     return response.json(); // Extract the response body as JSON
   })
   .then(data => {   
    

     if (data.d === 'checkterm') {
       // Handle the case when user is not found
       swal.fire({
        title: '<span style="color:#f27474">Opps!</span>',
        html: "Please select terms and privacy policy <br /><br /><a href='javascript:swal.close();' class='btn btn-primary btn-sm'>OK</a>",
        type: 'error',
        icon: "error",
        showCloseButton: true,
        showCancelButton: false,
        showConfirmButton: false
    });
   
      return;
     }
     else if(data.d === 'allreadyexist'){
        swal.fire({
            title: '<span style="color:#f27474">Opps!</span>',
            html: "Email address is already exists! <br /><br /><a href='javascript:swal.close();' class='btn btn-primary btn-sm'>OK</a>",
            type: 'error',
	        icon: "error",
            showCloseButton: true,
            showCancelButton: false,
            showConfirmButton: false
        });
    
        return;
     }
     else if(data.d === 'submited'){
       $("#thanksubmit").show();
        $("#formsubmit").hide();  
     
     }
   })      
 .catch((error) => {
   console.log(error);
 });

 
  }


  //////////////////////////////////////////////// handle handlesignwithmicrosoft ///////////////
const handlesignwithmicrosoft =() =>{
    window.location.href="https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=260368b9-0101-4720-b0d8-ff3337affe72&response_type=code&redirect_uri=https://app.zwato.com/LoginMicrosoft&response_mode=query&scope=User.Read";
  }



///////////////////////////////////////////////////// Login with Google /////////////////////////


const [ user, setUser ] = useState([]);
    const [ profile, setProfile ] = useState([]);

// const responseMessage = (response) => {
//   console.log(response);
// };
// const errorMessage = (error) => {
//   console.log(error);
// };
       const login = useGoogleLogin({
        onSuccess: (codeResponse) => setUser(codeResponse),
        onError: (error) => console.log('Login Failed:', error)
    });  


    useEffect(
     
      () => {
        fetchcurrentcountry();
          if (user) {
              axios
                  .get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`, {
                      headers: {
                          Authorization: `Bearer ${user.access_token}`,
                          Accept: 'application/json'
                      }
                  })
                  .then((res) => {
                      setProfile(res.data);
// console.log(res.data.email);
// console.log(res.data.name);
// console.log(localStorage.getItem("currentcountry"));
// console.log(localStorage.getItem("currentcallingcode"));
// console.log(localStorage.getItem("currentcurrency"));
// console.log(res.data);

                      const axios = require('axios');
                    //   let datas = JSON.stringify({
                    //     "googlelogindata": {           
                    //       "Email": res.data.email,
                    //       "FName": res.data.given_name,
                    //       "LName": res.data.family_name,
                    //       "country": localStorage.getItem("currentcountry"),
                    //       "currency": localStorage.getItem("currentcurrency"),
                    //       "countrycode": localStorage.getItem("currentcallingcode"),
                    //       "registertype":"Company",
                    //       "keepmesign": true 
                    //     }
                    //   });
                      let datas = JSON.stringify({                               
                          "Email": res.data.email,
                          "FName": res.data.given_name,
                          "LName": res.data.family_name,
                          "country": localStorage.getItem("currentcountry"),
                          "currency": localStorage.getItem("currentcurrency"),
                          "countrycode": localStorage.getItem("currentcallingcode"),
                          "registertype":"Company",
                          "keepmesign": true                        
                      });
                     // console.log(datas);
                      let config = {
                        method: 'post',
                        maxBodyLength: Infinity,
                        headers: { 
                          'Content-Type': 'application/json'
                        },
                        body : datas
                      };
                      //"url": "/userRegistration/ReactGoogleLogin",
                      fetch(`${API_BASE_URL}/userRegistration/ReactGoogleLogin`, config)
                      .then(response => {
                          if (!response.ok) {
                           // throw new Error('Network response was not ok');
                          }
                          return response.json(); // Extract the response body as JSON
                        })
                        .then(data => { 
                            let expires = new Date()
                          expires.setTime(expires.getTime() + (86000 * 1000));         
                          setCookie('access_token', `Bearer ${data.access_token}`, { path: '/',  expires});  
                          fetchlogindetails(data.d, data.access_token, data.msg);

                          //fetchlogindetails(data.d);

                         
                        })      
                      .catch((error) => {
                       // console.log("Test Error-"+error);
                       swal.fire({
                        title: 'Opps!',
                        html: "Updation in a progress! Please try after sometime.<br /><br /><a href='javascript:swal.close()' class='btn btn-primary btn-sm'>OK</a> ",
                        type: 'error',
                        icon: "error",
                        showCloseButton: true,
                        showCancelButton: false,
                        showConfirmButton: false
                    });  
                       // alert(error +" - Please check internet connection or API");
                       // console.log(error);
                        
                      });




                  })
                  .catch((err) => console.log(err));
          }
      },
      [user]
  );

  const [isOpen, setIsOpen] = useState(true);
  const handleClose = () => {
    setIsOpen(false);
    setTimeout(() => {
      setIsOpen(true); // Reset isOpen after the animation completes
    }, 100); // Adjust the delay as needed to match the animation duration
  };


  async function fetchlogindetails(regid, tockenid, tockenmsg){      

    try {
        const response = await fetch(`${API_BASE_URL}/userRegistration/getcompanydetailslogin?registrationid=${regid}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json; charset=utf-8'       
          },
        //   body: JSON.stringify({
        //     registrationid: regid       
        //   })
        });
        const data = await response.json();           
       //console.log(data.d.address1);  

       let expires = new Date()
       expires.setTime(expires.getTime() + (86000 * 1000));         
       setCookie('access_token', `Bearer ${tockenid}`, { path: '/',  expires});     
      setCookie('registrationid', data.d.registrationid, { path: '/',  expires});

      if(data.d.language =="" || data.d.language == null)
        data.d.language="English";

     localStorage.setItem('email', data.d.email);
     localStorage.setItem('currency', data.d.Currency);
     localStorage.setItem('sitelanguage', data.d.language);
     localStorage.setItem('dateformate', data.d.dateformate);      
     localStorage.setItem('regstatus', data.d.status);   
     fetchcurrentcountry();
     loginlogoff(data.d.registrationid);
     if(data.d.registertype=="Company"){
     window.location.href = '/dashboard';
     }
     else if(data.d.registertype=="Accountant"){
       window.location.href = '/dashboard';
     }
     else if(data.d.registertype=="Personal"){
        window.location.href = '/dashboard';
      }
     else{
       window.location.href = '/dashboard';
     }
  
    return false;        
      } catch (error) {
        // Handle errors here
        console.log("Test Error");
      }
  };


  const loginlogoff =(regid)=>{  
    const axios = require('axios');    
    // let datas = JSON.stringify({
    //   "loginlogoffdata": {
    //     "registrationid": regid,  
    //     "currentlogin": "Login"
    //   }
    // });
    let datas = JSON.stringify({    
        "registrationid": regid,  
        "currentlogin": "Login"    
    });
    //console.log(datas);
    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      headers: { 
        'Content-Type': 'application/json'
      },
      body : datas
    };
    //"url": "/userRegistration/LoginLogoff",
    fetch(`${API_BASE_URL}/userRegistration/LoginLogoff`, config)
    .then((response) => {
       if(response.ok){  
       }
    })
    .catch((error) => {
      console.log(error);
    });
  }
  



    let publicUrl = process.env.PUBLIC_URL + '/'
    return (
        <>
         <ToastContainer />
            <div className="login-8">
                <div className="container">
                <Form onSubmit={handlesubmit} id="formsubmit">
                    <div className="row justify-content-center login-box">
                       
                        <div className="col-lg-9 col-md-12 form-info">
                            <div className="form-section">
                                <div className="logo1 clearfix text-center">
                                    <Link to="/">
                                        <img src={publicUrl+"assets/images/logo-zwato.png"} alt="logo"/>
                                    </Link>
                                </div>                                
                                <h3 className="text-center">Register Now To Start Invoicing</h3>
                                <div className="login-inner-form">
                                    <div className="row">
                                        <div className="col-md-2">
                                            <p><strong>Register as :</strong></p>
                                        </div>
                                        <div className="col-md-10">
                                            <div className="m-b-20 m-checkbox-inline justify-content-between custom-radio-ml">
                                                <div className="form-check form-check-inline radio radio-primary">
                                                    <input className="form-check-input" id="radioinline1" type="radio"  checked={formFields.registertype === 'Company'} onChange={handleChange} name="registertype" value="Company"/>
                                                    <label className="form-check-label mb-0" htmlFor="radioinline1">Company</label>
                                                </div>
                                                <div className="form-check form-check-inline radio radio-primary">
                                                    <input className="form-check-input" id="radioinline2" type="radio"  checked={formFields.registertype === 'Accountant'} onChange={handleChange} name="registertype" value="Accountant"/>
                                                    <label className="form-check-label mb-0" htmlFor="radioinline2">Accountant</label>
                                                </div>
                                                <div className="form-check form-check-inline radio radio-primary">
                                                    <input className="form-check-input" id="radioinline3" type="radio"  checked={formFields.registertype === 'Personal'} onChange={handleChange} name="registertype" value="Personal"/>
                                                    <label className="form-check-label mb-0" htmlFor="radioinline3">Personal</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div><br />
                                    <div className="row">
                                        <div className="col-md-6">
                                            <Form.Group className="form-group form-box">
                                                <Form.Control type="text" name="fname" onChange={handleChange} value={fname} maxLength={"50"} required placeholder="First Name"/>
                                                <i className="fa-regular fa-user"></i>
                                            </Form.Group>
                                        </div>
                                        <div className="col-md-6">
                                            <Form.Group className="form-group form-box">
                                                <Form.Control type="text" name="lname" onChange={handleChange} value={lname} maxLength={"50"} placeholder="Last Name"/>
                                                <i className="fa-regular fa-user"></i>
                                            </Form.Group>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <InputGroup>
                                                <InputGroup.Text id="basic-addon1"><i className={`fi fi-${flag}`}></i></InputGroup.Text>
                                                <CountriesRegister name="Country" onChange={handleSelect} selectedcountry={showcurrency} id="country" /> 
                                            </InputGroup>
                                        </div>
                                        <div className="col-md-6">
                                        <InputGroup className="form-group form-box">
                                        <InputGroup.Text id="phonecode"></InputGroup.Text>
                                                <Form.Control type="text" name="phoneno" className="number phoneformate" required onChange={handleChange} maxLength={"20"} value={phoneno} placeholder="Phone Number"/>
                                             <i className="fa-solid fa-mobile"></i>
                                                </InputGroup>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <Form.Group className="form-group form-box">
                                                <Form.Control type="email" name="email" required className="validemail" maxLength={"70"} onChange={handleChange} value={email} placeholder="Email ID"/>
                                                <i className="fa-regular fa-envelope"></i>
                                            </Form.Group>
                                        </div>
                                        <div className="col-md-6">
                                            <Form.Group className="form-group form-box">
                                                <Form.Control type="email" name="confirmemail" className="validemail" maxLength={"70"} onChange={handleChange} value={confirmemail} required placeholder="Confirm Email ID"/>
                                                <i className="fa-solid fa-envelope"></i>
                                                <span id="cnfrmemailmatch" style={{floar:"left"}}></span>
                                            </Form.Group>

                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <Form.Group className="form-group form-box">
                                                <Form.Control type="text" name="companyname" onChange={handleChange} value={companyname} maxLength={"100"} placeholder="Company Name"/>
                                                <i className="fa-sharp fa-solid fa-building"></i>
                                            </Form.Group>
                                        </div>
                                        <div className="col-md-6">
                                            <Form.Group className="form-group form-box">
                                                <Form.Control type="text" name="Currency" onChange={handleChange} value={Currency} placeholder="Currency"/>
                                                <i className="fa-regular fa-solid fa-money-bill"></i>
                                            </Form.Group>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <Form.Group className="form-group form-box">
                                                <Form.Control type={showPassword ? "text" : "password"} name="password" required onChange={handleChange} value={password} placeholder="Password"/>
                                                <i onClick={togglePasswordVisibility}>
            {showPassword ? <BsFillEyeSlashFill /> : <BsFillEyeFill />}
          </i>
                                               
                                            </Form.Group>
                                            {errorMessage && <p style={{ color: 'red', fontSize:'9px', textAlign:'left', position:'relative', top:'-10px', clear:'both' }}>{errorMessage}</p>}
                                        </div>
                                        <div className="col-md-6">
                                            <Form.Group className="form-group form-box">
                                                <Form.Control type={showPasswordConfirm ? "text" : "password"} name="Confirm_password" onChange={handleChange} value={Confirm_password} placeholder="Confirm Password"/>
                                                <i onClick={togglePasswordVisibilityConfirm}>
            {showPassword ? <BsFillEyeSlashFill /> : <BsFillEyeFill />}
          </i>
          <span id="cnfrmpsmatch"></span>
                                               
                                            </Form.Group>
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <div className="col">
                                            <div className="form-check checkbox checkbox-dark p-l-0">
                                                <input type="checkbox" name="terms" checked={isChecked} onChange={handleChangeterm} className="radio_animated check checkbox-custom" id="checkbox-custom_04"/>
                                                <label htmlFor="checkbox-custom_04" className="checkbox-custom-label mt-0">I have read and accept Zwato <Link to="#" className="txt-primary"  data-bs-toggle='modal' data-bs-target='.termconditionpopup'>Terms and Privacy Policy</Link></label>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div className="form-group mb-0">
                                        <Button className="btn btn-info-gradien w-100" type="submit"><i className="fa-solid fa-arrow-right-to-bracket"></i> Register Now</Button>                    
                                    </div>
                                    <div className="do-mobile mt-3">
                                        <div className="fxt-style-line">
                                            <h2 className="text-dark">Or Register With</h2>
                                        </div>
                                        <ul className="social-list">
                                            <li>
                                                <a href="#" className="google-bg">
                                                    <i className="fa-brands fa-google"></i>
                                                </a>
                                            </li>
                                            <li>
                                                <Link to="#"  onClick={handlesignwithmicrosoft} className="microsoft-bg">
                                                    <i className="fa-brands fa-microsoft"></i>
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                    <p className="text">Already Have an Account? <Link to="/SignIn" className="txt-primary"> Sign In</Link></p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-12 bg-img">
                            <div className="info">
                                <div className="info-text">
                                    <div className="logo clearfix text-center">
                                        <Link to="/">
                                            <img src={publicUrl+"assets/images/logo-zwato-w.png"} alt="logo"/>
                                        </Link>
                                        <div className="fxt-style-line">
                                            <h2>Or Register With</h2>
                                        </div>
                                        <ul className="social-list">
                                            <li>
                                                <a href="#" className="google-bg" onClick={login}>
                                                    <i className="fa-brands fa-google"></i>
                                                </a>
                                            </li>
                                            <li>
                                                <Link to="#" onClick={handlesignwithmicrosoft} className="microsoft-bg">
                                                    <i className="fa-brands fa-microsoft"></i>
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                    </Form>

                   
     
                    <div className="login-8" id="thanksubmit">
                <div className="container">
                    <div className="row justify-content-center login-box">
                        <div className="col-lg-4 col-md-4 form-info">
                            <img src="https://media.zwato.com/images/inbox-icon-animation.gif" className="img-fluid"/>
                        </div>
                        <div className="col-lg-8 col-md-8 form-info">
                            <h3 className="">Thank You For Your Registration.</h3>
                            <p className="text-dark">Welcome to Zwato, the next-generation all-in-one software for entrepreneurs around the globe.</p>
                            <p className="text-dark">We have sent a verification email. <br/>Please check your inbox, including your junk or spam folder. <br/>Verify your email to continue. <a href="/signin" className="btn btn-square btn-light btn-xs f-12 text-danger">Back To Login Page</a></p>
                            <br/>
                            <p className="text-dark">
                                <a href="https://mail.google.com/" className="logincircle" target="_blank">
                                    <img src="https://media.zwato.com/images/gmail.png" className="img-fluid"/>
                                </a>
                                <a href="https://login.live.com/" className="logincircle" target="_blank">
                                    <img src="https://media.zwato.com/images/outlook.png" className="img-fluid"/>
                                </a>
                                <a href="https://login.yahoo.com/" className="logincircle" target="_blank">
                                    <img src="https://media.zwato.com/images/yahoo.png" className="img-fluid"/>
                                </a>
                                <a href="/signin" className="logincircle">
                                    <img src="https://media.zwato.com/images/login.png" className="img-fluid"/>
                                </a>
                                <a href="https://mail.rediff.com/cgi-bin/login.cgi" className="logincircle" target="_blank">
                                    <img src="https://media.zwato.com/images/rediff.png" className="img-fluid"/>
                                </a>
                            </p>
                        </div>
                    </div>
                </div>
            </div>

                </div>
            </div>

            <div class="modal fade termconditionpopup" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog rollIn animated modal-fullscreen modal-dialog-centered">
        <div class="modal-content">
			<div class="modal-header">
				<ul class="nav nav-tabs border-tab nav-primary m-b-0 no-border" id="info-tab" role="tablist">
					<li class="nav-item">
						<a class="nav-link modal-header-tab active" data-bs-toggle="tab" href="#terms" role="tab" aria-selected="true">
						<i class="fa-solid fa-building"></i> TERMS</a>
					</li>
					<li class="nav-item">
						<a class="nav-link modal-header-tab" data-bs-toggle="tab" href="#privacy_policy" role="tab" aria-selected="false">
						<i class="fa-solid fa-file-user"></i> PRIVACY POLICY</a>
					</li>
				</ul>
				<button class="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close"></button>
			</div>
			<div class="modal-body">            
				<div class="row g-2">
					<div class="col-md-12">
						<div class="card bg-34 m-b-10">                  
							<div class="card-body">                    
								<div class="tab-content">
									<div class="tab-pane fade show active" id="terms" role="tabpanel">
										<div class="card-header p-b-5 p-t-0 p-l-0">
										  <h4>View Our Terms & Conditions</h4>                
										</div>
										<div className="row">
											<div className="col-lg-12">
												<p className="mb-2">These terms and conditions outline the rules and regulations for the use of ZWATO INVOICING Website, located at Website.com.</p>
												<p className="mb-2">By accessing this website we assume you accept these terms and conditions. Do not continue to use Website Name if you do not agree to take all of the terms and conditions stated on this page.</p>
												<p className="mb-5">The following terminology applies to these Terms and Conditions, Privacy Statement and Disclaimer Notice and all Agreements: "Client", "You" and "Your" refers to you, the person log on this website and compliant to the Company's terms and conditions. "The Company", "Ourselves", "We", "Our" and "Us", refers to our Company. "Party", "Parties", or "Us", refers to both the Client and ourselves. All terms refer to the offer, acceptance and consideration of payment necessary to undertake the process of our assistance to the Client in the most appropriate manner for the express purpose of meeting the Client's needs in respect of provision of the Company's stated services, in accordance with and subject to, prevailing law of Netherlands. Any use of the above terminology or other words in the singular, plural, capitalization and/or he/she or they, are taken as interchangeable and therefore as referring to same.</p>
												<h3>Cookies</h3>
												<p className="mb-2">We employ the use of cookies. By accessing Website Name, you agreed to use cookies in agreement with the ZWATO INVOICING Privacy Policy.</p>
												<p className="mb-5">Most interactive websites use cookies to let us retrieve the user's details for each visit. Cookies are used by our website to enable the functionality of certain areas to make it easier for people visiting our website. Some of our affiliate/advertising partners may also use cookies.</p>
												
												
												<h3>License</h3>
												<p className="mb-2">Unless otherwise stated, ZWATO INVOICING and/or its licensors own the intellectual property rights for all material on Website Name. All intellectual property rights are reserved. You may access this from Website Name for your own personal use subjected to restrictions set in these terms and conditions.</p>
												<p className="mb-2"><strong>You must not:</strong></p>
												<ul className="mb-2 auto-list">
													<li>Republish material from ZWATO INVOICING</li>
													<li>Sell, rent or sub-license material from ZWATO INVOICING</li>
													<li>Reproduce, duplicate or copy material from ZWATO INVOICING</li>
													<li>Redistribute content from ZWATO INVOICING</li>
												</ul>
												<p className="mb-2">This Agreement shall begin on the date hereof.</p>
												<p className="mb-2">Parts of this website offer an opportunity for users to post and exchange opinions and information in certain areas of the website. ZWATO INVOICING does not filter, edit, publish or review Comments prior to their presence on the website. Comments do not reflect the views and opinions of ZWATO INVOICING,its agents and/or affiliates. Comments reflect the views and opinions of the person who post their views and opinions. To the extent permitted by applicable laws, ZWATO INVOICING shall not be liable for the Comments or for any liability, damages or expenses caused and/or suffered as a result of any use of and/or posting of and/or appearance of the Comments on this website.</p>
												<p className="mb-5">ZWATO INVOICING reserves the right to monitor all Comments and to remove any Comments which can be considered inappropriate, offensive or causes breach of these Terms and Conditions.</p>
												
												<h3>iFrames</h3>
												<p className="mb-5">Without prior approval and written permission, you may not create frames around our Webpages that alter in any way the visual presentation or appearance of our Website.</p>
												
												<h3>Content Liability</h3>
												<p className="mb-5">We shall not be hold responsible for any content that appears on your Website. You agree to protect and defend us against all claims that is rising on your Website. No link(s) should appear on any Website that may be interpreted as libelous, obscene or criminal, or which infringes, otherwise violates, or advocates the infringement or other violation of, any third party rights.</p>
												<h3>Reservation of Rights</h3>
												<p className="mb-5">We reserve the right to request that you remove all links or any particular link to our Website. You approve to immediately remove all links to our Website upon request. We also reserve the right to amen these terms and conditions and it's linking policy at any time. By continuously linking to our Website, you agree to be bound to and follow these linking terms and conditions.</p>
												<h3>Removal of Links From Our Website</h3>
												<p className="mb-2">If you find any link on our Website that is offensive for any reason, you are free to contact and inform us any moment. We will consider requests to remove links but we are not obligated to or so or to respond to you directly.</p>
												<p className="mb-2">We do not ensure that the information on this website is correct, we do not warrant its completeness or accuracy; nor do we promise to ensure that the website remains available or that the material on the website is kept up to date.</p>
											</div>
										</div>
									</div>
									<div class="tab-pane fade" id="privacy_policy" role="tabpanel">
										<div class="card-header p-b-5 p-t-0 p-l-0">
										  <h4>View Our Privacy Policy</h4>                
										</div>
										<div className="row">
											<div className="col-lg-12">
												<p className="mb-5">This privacy policy governs the manner in which Zwato collects, users, maintains and discloses information collected from user(s) of the website/application. This privacy policy applies to the website and all products and services offered by Zwato. For more details about us, refer the last section- "Contact us".</p>
												<h3>What information we collect?</h3>
												<p className="mb-2">We collect and proceed the following information:</p>
												<h6><strong>(a) Personal identification information</strong></h6>
												<p className="mb-2">We may collect personal identification information from users in a variety of ways, including, but not limited to, when users visit our website/application, subscribe to the newsletter, fill out a form, and be in contact with our activities, services, features or resources we make available on our website/application.</p>
												<p className="mb-2">Users may be asked for, as appropriate, name and surname, email address, phone number, user ID on Internet messengers (Skype etc.), name of the company which they represent, job position in the company, user feedback.</p>
												<p className="mb-4">Users may, however, visit our website anonymously. We will collect personal identification information from users only if they voluntarily submit such information to us. Users can always refuse to supply personal identification information, except that it may prevent them from engaging in certain site-related activities.</p>
												<h6><strong>(b) Non-personal identification information</strong></h6>
												<p className="mb-4">We may collect non-personal identification information about users whenever they interact with us through our website. Non-personal identification information may include the browser name, the type of computer and technical information about user’s means of connection to our site, such as the operating system and the internet service providers utilized and other similar information.</p>
												<h6><strong>(c) Web browser cookies</strong></h6>
												<p className="mb-5">Our website may use cookies to enhance user experience. A user's website browser places cookies on their hard drive for record-keeping purposes and sometimes to track information about them. User may choose to set their web browser to refuse cookies, or to alert them when cookies are being sent. If they do so, note that some parts of the website may not function properly.</p>
												<h3>What are the legal bases for procession of information?</h3>
												<p className="mb-2">We can process information on the following legal bases:</p>
												<h6><strong>(a) regarding personal identification information:</strong></h6>
												<ol className="mb-4 auto-number">
													<li>users consent (when user agrees to send the personal information);</li>
													<li>our legitimate interest, namely offering our services to the user or the company the user represents, negotiation and conclusion of respective contracts, and performance under such contracts;</li>
												</ol>
												<h6><strong>(b) regarding non-personal identification information and web browser cookies:</strong></h6>
												<ol className="mb-5 auto-number">
													<li>users consent;</li>
													<li>our legitimate interest, namely proper administration of our website.</li>
												</ol>
												<h3>How we use collected information</h3>
												<p className="mb-2">ZWATO INVOICING may collect and use a user’s personal information for following purposes:</p>
												<ul className="mb-5 auto-list">
													<li>To offer our services and products to the user or the company the user represents.</li>
													<li>To provide our services and products to the user or the company the user represents.</li>
													<li>To fulfill legal obligations based on agreements or other arrangements with the user or the company the user represents.</li>
													<li>To manage and coordinate our relationship with the user or the company the user represents.</li>
													<li>To personalize user experience: we may use information in the aggregate to understand how are users as a group can use the services and resource provided on our website/application.</li>
													<li>To improve our site: we may use the feedback users provide to improve our products and services.</li>
													<li>To run a promotion, contest, survey or any other website feature: to send users information they agreed to receive about topics we think will be of best interest to them.</li>
													<li>To send periodic emails: we may use the email address to respond to their enquiries, questions, and/or other requests. If a user decides to opt in to our mailing list, they will receive emails that may include company news, updates, related product or service information, etc. If at any time the user would like to unsubscribe from receiving any future emails, we include a detailed unsubscribe instruction at the bottom of each email.</li>
													<li>For any other legitimate business purposes. Information is collected only to the extent necessary for processing purposes.</li>
												</ul>
												<h3>What is the term of procession (storage) of information?</h3>
												<p className="mb-2">ZWATO INVOICING stores the information collected within the following terms:</p>
												<p className="mb-2">(a) Personal identification information- 10 years after the users last enquiry (in case no contact was conducted after the respective enquiry), or 10 years after the last contact with user or the company which they represent, terminates;</p>
												<p className="mb-5">(b) Non-personal identification information and web browser cookies- one year after the respective information was collected.</p>
												<h3>How we protect your information</h3>
												<p className="mb-5">We adopt appropriate data collection, storage and processing practices and security measures to protect against unauthorized access, alteration, disclosure or destruction of a user's personal information and data stored in our website/application.</p>
												<h3>Sharing your personal information</h3>
												<p className="mb-2">We do not sell, trade, or rent your personal identification information to others. We may share generic aggregated demographic information not linked to any personal identification information regarding visitors and users with our business partners, trusted affiliates and advertisers for the purposes outlined above. We may use third-party service providers to help us operate our business and the website or administer activities on our behalf, such as sending out newsletters or surveys. We may share your information with these third parties for those limited purposes provided that you have given us the permission.</p>
												<p className="mb-5">We reserve the right to provide your personal information inside our company.</p>
												<h3>The basic rights of users</h3>
												<p className="mb-2">The user has the following rights regarding information collected by us:</p>
												<ul className="mb-3 auto-list">
													<li>to request access to the information related to the user;</li>
													<li>to request rectification of the information related to the user;</li>
													<li>to request erasure of the information related to the user from our database;</li>
													<li>to cancel (withdraw) user's consent to the processing of the information related to the user;</li>
													<li>to request a restriction on the processing of the information related to the user;</li>
													<li>to request a transfer of the information related to the user to another controller;</li>
													<li>to object to the processing of information related to the user for direct marketing.</li>
												</ul>
												<p className="mb-2">If we, upon the user's request, erase information related to them from our database, we will keep the information that may be necessary for protecting our legitimate interests or for public authorities.</p>
												<p className="mb-2">If the user requires us to transfer information related to them to another controller, rectify it or provide access to it, we may first require further information in order to verify user’s identity.</p>
												<p className="mb-5">If the user is one of the opinion that his/her rights related to the protection of information related to them were breached, the user may appeal to us or submit a complaint to the relevant authority for the protection of personal data in the user's respective country (the country of user’s residence).</p>
												<h3>Changes to this privacy policy</h3>
												<p className="mb-2">Zwato. has the discretion to update this privacy policy at any time. When we do, we will revise the updated date at the bottom of this page. We encourage every user to frequently check the space for any changes to stay informed about how we are helping to protect the personal information we collect.</p>
												<p className="mb-5">You acknowledge and agree that it is your responsibility to review this privacy policy periodically and become aware of the followed modifications.</p>
												<h3>Your acceptance of these terms</h3>
												<p className="mb-2">By using this website/application and sharing your contact details with Zwato, you signify your acceptance to this Privacy Policy.</p>
												<p className="mb-2">The website/application is available only to individuals who are at least 16 years' old. By using this website/application you agree that you are at least 16 years old. If you do not agree to this Privacy Policy, please do not use our website/application. Your continued use of this website/application following the posting of changes to this Policy will be deemed to your acceptance of those changes.</p>
												<p className="mb-5">Your acceptance of these terms</p>
												<h3>Contact Us</h3>
												<p className="mb-5">If you have any questions about this Privacy Policy, the practices of this website/application or your dealings with the website/application please contact us at:</p>
												<blockquote className="block-quote style-1">
													<p className="mb-1"><strong>Zwato.</strong></p>
															<p className="mb-1"><strong>Level 1, Pine Valley, Embassy Golf Links, IRR, Bengaluru- 560071</strong></p>
															<p className="mb-1"><strong>Call us: +358 7532 509 80</strong></p>
															<p className="mb-1"><strong>Email us: <a href="mailto:info@zwato.com">info@zwato.com</a></strong></p>
												</blockquote>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>              
				</div>
			</div>
        </div>
    </div>
</div>
    



        </>
    );

}
export  default RegisterNow;