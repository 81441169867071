import React, {useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom';
import $ from "jquery";
import Cookies from 'js-cookie';
import swal from "sweetalert2";
import { API_BASE_URL, MEDIA_BASE_URL, BASE_URL } from "../../config/config";
import { encrypt, decrypt } from '../../config/encrypt-decrypt';

function PaymentResponse () {

//////////////////////////////////////////////////////Get Company Details //////////////////////
const [companydetails, setCompanyDetails] = useState([]);
async function fetchcompanydetails(regno) {
	try {
	  const response = await fetch(`${API_BASE_URL}/api/getCompanyPayment?registrationid=${regno}`, {
		method: 'GET',
		headers: {		 
		  'Content-Type': 'application/json; charset=utf-8'
		},		
	  });	  
	  const data = await response.json();
	  setCompanyDetails(data.d);  
	} catch (error) {
	  console.log("Test Error");
	}
  }

    //////////////////////////////////////////////////////Get Invoice Details //////////////////////
const [invoicedetails, setInvoiceDetails] = useState([]);
const [invoiceproduct, setInvoiceProduct] = useState([]);
async function fetchinvoicedetails(invoiceno,regno) {
	debugger;
	try {
	  const response = await fetch(`${API_BASE_URL}/invoice/GetInvoiceDetailsPayment?registrationid=${regno}&invoiceno=${invoiceno}`, {
		method: 'GET',
		headers: {
		 // Authorization: Cookies.get("access_token"),
		  'Content-Type': 'application/json; charset=utf-8'
		},
		// body: JSON.stringify({
		//   registrationid: Cookies.get("registrationid")       
		// })
	  });	  
	  const data = await response.json();
	  debugger;
	  setInvoiceDetails(data.d);
	  setInvoiceProduct(data.prod);   
    
	} catch (error) {
	  console.log("Test Error");
	}
  }


    const [invoiceno, setInvoiceNo] = useState("");
    const [dueamount, setDueAmount] = useState("");
    useEffect(() => {
        debugger;
      let search = window.location.search;
    let params = new URLSearchParams(search);
      let invoiceno = decrypt(params.get('invoiceno'));
      let regno = decrypt(params.get('regno'));
      let amount = params.get("amount");
      let customerid=decrypt(params.get("cstmrid"));
      let referenceno=decrypt(params.get("rfncno"));

      setInvoiceNo(invoiceno);
      setDueAmount(amount);

      fetchcompanydetails(regno);
      fetchinvoicedetails(invoiceno, regno);


    },[]);
  
      return (
  <>
    <section className="cont-area h-100">
	<div className="container h-100">
		<div className="row h-100 justify-content-center align-items-center">
			<div className="col-md-6 text-center">
				<div className="login-sec1 animate__animated animate__zoomInDown">
					<h2><i className="mdi mdi-check-circle-outline text-success"></i></h2>
					<h4><strong>Thank You !</strong></h4>
					<p className="mb-3">Your Payment for Invoice No. <strong>#{invoiceno}</strong> and  <br/>Due Amount <strong>{dueamount}</strong> is Successful.</p>	
					
					<div className="row mb-3 justify-content-center">
						<div className="col-12 text-center">

							<button type="button" className="btn btn-primary btn-sm">View Receipt</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>
  </>
      );
    };
    export default PaymentResponse;
