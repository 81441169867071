import React, { useRef, useState } from "react";
import IdleTimer from "react-idle-timer";
import SessionTimeoutDialog from "./SessionTimeoutDialog";
import Cookies from 'js-cookie';
import { useCookies } from 'react-cookie';
import {API_BASE_URL} from "../../config/config";
let countdownInterval;
let timeout;

const SessionTimeout = ({isAuthenticated, logOut}) => {
  const [timeoutModalOpen, setTimeoutModalOpen] = useState(false);
  const [timeoutCountdown, setTimeoutCountdown] = useState(0);
  const idleTimer = useRef(null);

  const clearSessionTimeout = () => {
    clearTimeout(timeout);
  };

  const clearSessionInterval = () => {
    clearInterval(countdownInterval);
  };

  const handleLogout = async (isTimedOut = false) => {
    try {
        setTimeoutModalOpen(false);
        clearSessionInterval();
        clearSessionTimeout();
        window.location.href="/SignOut";
        logOut();
     } catch (err) {
        console.error(err);
      }
  };

  const handleContinue = () => {
    setTimeoutModalOpen(false);
    clearSessionInterval();
    let regid=Cookies.get("registrationid");
   fetchlogindetails(regid)   
    clearSessionTimeout();
  };

  const [cookies, setCookie] = useCookies(Cookies.get("access_token"));
  async function fetchlogindetails(regid){      

    try {
     // url: `${API_BASE_URL}/api/getCompany?registrationid=${registrationid}`,
  
          const response = await fetch(`${API_BASE_URL}/api/getCompany?registrationid=${regid}`, {
          method: 'get',
          headers: {
            "Authorization": Cookies.get("access_token"),
            'Content-Type': 'application/json; charset=utf-8'       
          },
          // body: JSON.stringify({
          //   registrationid: cookies       
          // })
        });
        const data = await response.json();           
       //console.log(data.d.address1);  
       
     let expires = new Date()
     expires.setTime(expires.getTime() + (86000 * 1000));         
     setCookie('access_token', Cookies.get("access_token"), { path: '/',  expires});
     setCookie('registrationid', data.d.registrationid, { path: '/',  expires});

     localStorage.setItem('email', data.d.email);
     localStorage.setItem('currency', data.d.Currency);
     localStorage.setItem('sitelanguage', data.d.language);
     localStorage.setItem('dateformate', data.d.dateformate);      
     localStorage.setItem('regstatus', data.d.status);
     
    return false;        
      } catch (error) {
        // Handle errors here
        console.log("Test Error");
      }
  };


  const onActive = () => {
    if (!timeoutModalOpen) {
      clearSessionInterval();
      clearSessionTimeout();
    }
  };

  const onIdle = () => {
    const delay = 1000 * 1;
    if (isAuthenticated && !timeoutModalOpen) {
      timeout = setTimeout(() => {
        let countDown = 60;
        setTimeoutModalOpen(true);
        setTimeoutCountdown(countDown);
        countdownInterval = setInterval(() => {
          if (countDown > 0) {
            setTimeoutCountdown(--countDown);
          } else {
            handleLogout(true);
          }
        }, 1000);
      }, delay);
    }
  };

  return (
    <>
      <IdleTimer
        ref={idleTimer}
        onActive={onActive}
        onIdle={onIdle}
        debounce={250}
        timeout={180000}
      />
      <SessionTimeoutDialog
        countdown={timeoutCountdown}
        onContinue={handleContinue}
        onLogout={() => handleLogout(false)}
        open={timeoutModalOpen}
      />
    </>
  );
}

export default SessionTimeout;