import CryptoJS from 'crypto-js';

//const algorithm = process.env.TOKEN_ALGORITHM_CRYPTO;
//const secretKey = process.env.TOKEN_SECRET_CRYPTO;
//TOKEN_SECRET=1903332cf4ced82311a949443700527e49db7dc9e51d4119ba6a9e1c863e82a61cdce2b5285a541fa8ed2f1
const secretKey="82a61244acd6a52";
const algorithm="aes-256-ctr";
//import CryptoJS from 'crypto-js';

const key = CryptoJS.enc.Utf8.parse(secretKey.padEnd(32, '0')); 
const encrypt = (text) => {
  console.log(text);
   text = text.toString();
  // Generate a random 16-byte IV
  const iv = CryptoJS.lib.WordArray.random(16);
  const encrypted = CryptoJS.AES.encrypt(text, CryptoJS.enc.Utf8.parse(key), {
    iv: iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  });

  return iv.toString(CryptoJS.enc.Hex) + '$$' + encrypted.ciphertext.toString(CryptoJS.enc.Hex);
};

// Decrypt function
const decrypt = (hash) => {
  const parts = hash.split('$$');
  const iv = CryptoJS.enc.Hex.parse(parts[0]);
  const content = parts[1];

  const decrypted = CryptoJS.AES.decrypt(
    { ciphertext: CryptoJS.enc.Hex.parse(content) },
    CryptoJS.enc.Utf8.parse(key),
    {
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    }
  );

  return decrypted.toString(CryptoJS.enc.Utf8);
};

// decUser function
const decUser = (content) => {
  // This function logic seems redundant as it doesn't use iv correctly
  const decrypted = CryptoJS.AES.decrypt(
    { ciphertext: CryptoJS.enc.Hex.parse(content) },
    CryptoJS.enc.Utf8.parse(secretKey),
    {
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    }
  );

  return decrypted.toString(CryptoJS.enc.Utf8);
};

// Export the functions
export { encrypt, decrypt, decUser };
